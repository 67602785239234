<template>
  <!--begin::Post-->
  <div class="post d-flex flex-column-fluid" id="kt_post">
    <!--begin::Container-->
    <div id="kt_content_container" class="container">
      <!--begin::Basic info-->
      <div class="card mb-5 mb-xl-10">
        <!--begin::Card header-->
        <div
          class="card-header border-0 cursor-pointer"
          role="button"
          data-bs-toggle="collapse"
          data-bs-target="#kt_account_profile_details"
          aria-expanded="true"
          aria-controls="kt_account_profile_details"
        >
          <!--begin::Card title-->
          <div class="card-title m-0">
            <h3 class="fw-bolder m-0">เพิ่มสถานที่ท่องเที่ยว</h3>
          </div>
          <!--end::Card title-->
        </div>
        <!--begin::Card header-->

        <!--begin::Content-->
        <div id="kt_account_profile_details" class="collapse show">
          <!--begin::Form-->
          <Form
            @submit="onSubmitTourist"
            :validation-schema="tourist"
            id="kt_account_profile_details_form"
            class="form"
            novalidate="novalidate"
          >
            <!--begin::Card body-->
            <div class="card-body border-top p-9">
              <!--begin::Input group-->
              <div class="row mb-6">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label required fw-bold fs-6"
                  >ชื่อสถานที่</label
                >
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <Field
                    type="text"
                    name="name"
                    class="form-control form-control-lg form-control-solid"
                    placeholder="ชื่อสถานที่"
                  />
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="name" />
                    </div>
                  </div>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->
              <!--begin::Input group-->
              <div class="row mb-6">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label required fw-bold fs-6"
                  >รายละเอียด</label
                >
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <Field
                    type="text"
                    name="detail"
                    class="form-control form-control-lg form-control-solid"
                    placeholder="รายละเอียด"
                  />
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="detail" />
                    </div>
                  </div>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->

             

              <!--begin::Input group-->
              <div class="row mb-6">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label required fw-bold fs-6"
                  >รูปหน้าแรก</label
                >
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <div class="d-flex justify-center align-center">
                    <div style="width: 256px; height: 256px; padding: 0px 3px">
                      <Field type="hidden" name="image" v-model="imageLogo" />
                      <div v-if="imageLogo" style="position: relative">
                        <img class="image-logo" :src="imageLogo" alt="" />
                        <button
                          @click="deleteImage"
                          class="btn btn-danger bt-delete"
                        >
                          x
                        </button>
                      </div>
                      <button
                        v-else
                        type="button"
                        class="
                          form-control
                          btn btn-outline-secondary btn-new-icon
                        "
                        data-bs-toggle="modal"
                        data-bs-target="#modal_new_icon"
                      >
                        <img
                          src="@/assets/picture.png"
                          class="icon-ima"
                        /><br />
                        แนบรูป
                      </button>
                      <div class="fv-plugins-message-container">
                        <div class="fv-help-block">
                          <ErrorMessage name="icon" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->


                            <!--begin::Input group-->
              <div class="row mb-6">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label required fw-bold fs-6"
                  >เนื้อหา</label
                >
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-8 fv-row ck">
                  <Field type="hidden" name="content" v-model="content" />
             <ckeditor
                :editor="editor"
                :config="editorConfig"
                v-model="content"
            />
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->





            </div>
            <!--end::Card body-->

            <!--begin::Actions-->
            <div class="card-footer d-flex justify-content-end py-6 px-9">
              <button
                type="submit"
                id="kt_account_profile_details_submit"
                ref="submitButton"
                class="btn btn-primary"
              >
                <span class="indicator-label"> ยืนยัน </span>
                <span class="indicator-progress">
                  กรุณารอสักครู่ ...
                  <span
                    class="spinner-border spinner-border-sm align-middle ms-2"
                  ></span>
                </span>
              </button>
            </div>
            <!--end::Actions-->
          </Form>
          <!--end::Form-->
        </div>
        <!--end::Content-->
      </div>
      <!--end::Basic info-->
    </div>
  </div>
  <CropImage :aspectRatio="4/3" :height="1200" :width="1200" @clicked="satImageLogo"></CropImage>
</template>

<script>
import CropImage from "@/components/modals/CropImage.vue";
import { defineComponent, onMounted, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import * as Yup from "yup";
import useSweetalert from "@/core/helpers/sweetalert2";
import useTourists from "@/core/services/api/travel/tourists";
import { checkPage } from "@/core/helpers/checkpage";
import "vue-advanced-cropper/dist/style.css";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import UploadAdapter from '@/core/services/UploadAdapter';
export default defineComponent({
  name: "tourist-attraction-create",
  components: {
    ErrorMessage,
    Field,
    Form,
    CropImage,
  },
  setup() {
    const { stoerTourist } = useTourists();
    const { Sconfirm, SToast } = useSweetalert();
    const submitButton = ref(null);
    const imageLogo = ref("");
    const content = ref("");

    const tourist = Yup.object().shape({
      name: Yup.string()
        .required("กรุณากรอก ชื่อสถานที่")
        .label("ชื่อสถานที่"),
      detail: Yup.string()
        .required("กรุณากรอก รายละเอียด")
        .label("รายละเอียด"),
      image: Yup.string().required("กรุณาเลือก รูปภาพ").label("รูปภาพ"),
    });

    const onSubmitTourist = (values) => {
      Sconfirm("ยืนยัน เพิ่มสถานที่ท่องเที่ยว", "question").then(
        async (result) => {
          if (result.isConfirmed) {
            if (submitButton.value) {
              // Activate indicator
              submitButton.value.setAttribute("data-kt-indicator", "on");
              submitButton.value.setAttribute("disabled", "disabled");
            }
            await stoerTourist(values)
              .then(() => {
                SToast("success", "เพิ่ม สถานที่ท่องเที่ยว สำเร็จ");
              })
              .catch(() => {
                submitButton.value?.removeAttribute("data-kt-indicator");
                submitButton.value?.removeAttribute("disabled");
                
              });
          }
        }
      );
    };

    function satImageLogo(image) {
      imageLogo.value = image;
    }

    function deleteImage() {
      imageLogo.value = "";
    }


    function uploader(editor)
                {
                    editor.plugins.get( 'FileRepository' ).createUploadAdapter = ( loader) => {
                        return new UploadAdapter( loader,'tourist_upload');
                    };
                }

    onMounted(() => {
      checkPage("tourist-attraction-create");
      setCurrentPageBreadcrumbs("เพิ่มสถานที่ท่องเที่ยว", ["แนะนำที่ท่องเที่ยว"]);
    });



    return {
      tourist,
      imageLogo,
      content,
      submitButton,
      onSubmitTourist,
      satImageLogo,
      deleteImage,
       editor: ClassicEditor,
         editorConfig: {
           toolbar: [ 'bold', 'italic', 'link', 'undo', 'redo', 'numberedList', 'bulletedList','Alignment','|',
           'insertImage','mediaEmbed','|',
           'fontColor', 'fontBackgroundColor','fontFamily','fontSize','resizeImage'],
           shouldNotGroupWhenFull: true,
           fontSize: {
            options: [
                9,
                11,
                13,
                15,
                17,
                19,
                21
            ]
        },
                     fontColor: {
            colors: [
                {
                    color: 'hsl(0, 0%, 0%)',
                    label: 'Black'
                },
                {
                    color: 'hsl(0, 0%, 30%)',
                    label: 'Dim grey'
                },
                {
                    color: 'hsl(0, 0%, 60%)',
                    label: 'Grey'
                },
                {
                    color: 'hsl(0, 0%, 90%)',
                    label: 'Light grey'
                },
                {
                    color: 'hsl(0, 0%, 100%)',
                    label: 'White',
                    hasBorder: true
                },
                {
                    color: 'maroon',
                    label: 'maroon',
                },
                 {
                    color: 'red',
                    label: 'red',
                },
                 {
                    color: 'purple',
                    label: 'purple',
                },
                 {
                    color: 'fuchsia',
                    label: 'fuchsia',
                },
                 {
                    color: 'green',
                    label: 'green',
                },
                 {
                    color: 'lime',
                    label: 'lime',
                },
                 {
                    color: 'olive',
                    label: 'olive',
                },
                 {
                    color: 'yellow',
                    label: 'yellow',
                },
                 {
                    color: 'navy',
                    label: 'navy',
                },
                {
                    color: 'blue',
                    label: 'blue',
                },
                {
                    color: 'teal',
                    label: 'teal',
                },
                {
                    color: 'aqua',
                    label: 'aqua',
                },

                // ...
            ]
        },
          image: {
			resizeUnit: "%",
			resizeOptions:[{
				name: 'resizeImage:original',
				value: null,
				label: 'Original size'
			},{
				name: 'resizeImage:25',
				value: '25',
				label: '25%'
			},{
				name: 'resizeImage:50',
				value: '50',
				label: '50%'
			},{
				name: 'resizeImage:75',
				value: '75',
				label: '75%'
			},{
				name: 'resizeImage:90',
				value: '90',
				label: '90%'
			},{
				name: 'resizeImage:100',
				value: '100',
				label: '100%'
			}]
		},
        
                        table: {
                            toolbar: [ 'tableColumn', 'tableRow', 'mergeTableCells' ]
                        },
                        extraPlugins: [uploader],
                        language: 'nl',
                    },
           
    };
  },
});
</script>
<style scoped>
.bt-delete {
  position: absolute;
  right: 0px;
  top: 0px;
  padding: 10px !important;
  border-radius: 0px;
}
.image-logo {
  width: 100%;
  height: 100%;
  box-shadow: 0px 0px 20px 0px rgb(76 87 125 / 2%);
  border: 1px solid #eff2f5;
}
.btn-new-icon {
  width: 250px;
  height: 250px;
  background-color: #f5f8fa;
}
.ck{
  width: 375px;
}
</style>
